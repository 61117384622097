.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #3e6276;
  font-family: 'Roboto';
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content {
  margin-top: 12vh;
  min-height: calc(90vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.card {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 5px;
  min-height: 100px;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 10px 10px 10px;
  justify-content: center;
  align-items: center;
}

.subheading {
  display: flex;
    margin-top: -20px;
    font-size: 0.8rem;
}
.card .badge {
  top: -20px;
  right: -6px;
  color: white;
  background-color: #659dbd;
}

.scripture {
  font-family: Roboto;
  font-weight: bold;
  font-style: italic;
  background-color: #fbeec1;
  color: #a09879
}

.groups {
  padding-bottom: 12px;
}

.App-footer {
  height: 50px;
  background-color: #3e6276;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding-left: 5px;
  color: #659dbd;
}

